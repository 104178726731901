import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import HomeFr from '../views/HomeFr.vue'
import HomeIt from '../views/HomeIt.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      lang: 'en'
    }
  },
  {
    path: '/fr',
    name: 'HomeFr',
    component: HomeFr,
    meta: {
      lang: 'fr'
    }
  },
  {
    path: '/it',
    name: 'HomeIt',
    component: HomeIt,
    meta: {
      lang: 'it'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
