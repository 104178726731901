<template>
  <div class="wrapper text-center df jcc aic fc">
    <div class="logo">
      <img src="/img/logo.png" alt="Logo" />
    </div>
    <div class="logo-mobile">
      <img src="/img/logo-mobile.png" alt="Logo" />
    </div>
    <Form />
  </div>
  <Footer />
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 740px) {
    height: auto;
    padding-top: 3rem;
  }
  background-color: #21283c;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/img/bg.jpg");
}
</style>
