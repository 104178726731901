const countries = [
  {
    country_id: "1",
    name: "Afghanistan",
    iso_alpha2_code: "AF",
    tel_country_code: "93",
    show_on_register: "1",
  },
  {
    country_id: "283",
    name: "Aland Islands",
    iso_alpha2_code: "AX",
    tel_country_code: "358",
    show_on_register: "0",
  },
  {
    country_id: "2",
    name: "Albania",
    iso_alpha2_code: "AL",
    tel_country_code: "355",
    show_on_register: "1",
  },
  {
    country_id: "3",
    name: "Algeria",
    iso_alpha2_code: "DZ",
    tel_country_code: "213",
    show_on_register: "1",
  },
  {
    country_id: "266",
    name: "American Samoa",
    iso_alpha2_code: "AF",
    tel_country_code: "684",
    show_on_register: "0",
  },
  {
    country_id: "5",
    name: "Andorra",
    iso_alpha2_code: "AF",
    tel_country_code: "376",
    show_on_register: "1",
  },
  {
    country_id: "6",
    name: "Angola",
    iso_alpha2_code: "AO",
    tel_country_code: "244",
    show_on_register: "1",
  },
  {
    country_id: "7",
    name: "Anguilla",
    iso_alpha2_code: "AI",
    tel_country_code: "809",
    show_on_register: "1",
  },
  {
    country_id: "8",
    name: "Antarctica",
    iso_alpha2_code: "AQ",
    tel_country_code: "672",
    show_on_register: "0",
  },
  {
    country_id: "9",
    name: "Antigua and Barbuda",
    iso_alpha2_code: "AF",
    tel_country_code: "1268",
    show_on_register: "1",
  },
  {
    country_id: "11",
    name: "Argentina",
    iso_alpha2_code: "AR",
    tel_country_code: "54",
    show_on_register: "1",
  },
  {
    country_id: "12",
    name: "Armenia",
    iso_alpha2_code: "AM",
    tel_country_code: "374",
    show_on_register: "1",
  },
  {
    country_id: "13",
    name: "Aruba",
    iso_alpha2_code: "AW",
    tel_country_code: "297",
    show_on_register: "1",
  },
  {
    country_id: "249",
    name: "Ascension Island",
    iso_alpha2_code: "AF",
    tel_country_code: "247",
    show_on_register: "1",
  },
  {
    country_id: "14",
    name: "Australia",
    iso_alpha2_code: "AU",
    tel_country_code: "61",
    show_on_register: "1",
  },
  {
    country_id: "15",
    name: "Austria",
    iso_alpha2_code: "AT",
    tel_country_code: "43",
    show_on_register: "1",
  },
  {
    country_id: "16",
    name: "Azerbaijan",
    iso_alpha2_code: "AF",
    tel_country_code: "994",
    show_on_register: "1",
  },
  {
    country_id: "17",
    name: "Bahamas",
    iso_alpha2_code: "BS",
    tel_country_code: "1242",
    show_on_register: "1",
  },
  {
    country_id: "18",
    name: "Bahrain",
    iso_alpha2_code: "BH",
    tel_country_code: "973",
    show_on_register: "1",
  },
  {
    country_id: "19",
    name: "Bangladesh",
    iso_alpha2_code: "BD",
    tel_country_code: "880",
    show_on_register: "1",
  },
  {
    country_id: "20",
    name: "Barbados",
    iso_alpha2_code: "BB",
    tel_country_code: "1246",
    show_on_register: "1",
  },
  {
    country_id: "21",
    name: "Belarus",
    iso_alpha2_code: "BY",
    tel_country_code: "375",
    show_on_register: "1",
  },
  {
    country_id: "22",
    name: "Belgium",
    iso_alpha2_code: "BE",
    tel_country_code: "32",
    show_on_register: "0",
  },
  {
    country_id: "23",
    name: "Belize",
    iso_alpha2_code: "BZ",
    tel_country_code: "501",
    show_on_register: "1",
  },
  {
    country_id: "24",
    name: "Benin",
    iso_alpha2_code: "BJ",
    tel_country_code: "229",
    show_on_register: "1",
  },
  {
    country_id: "25",
    name: "Bermuda",
    iso_alpha2_code: "BM",
    tel_country_code: "1441",
    show_on_register: "1",
  },
  {
    country_id: "26",
    name: "Bhutan",
    iso_alpha2_code: "BT",
    tel_country_code: "975",
    show_on_register: "1",
  },
  {
    country_id: "27",
    name: "Bolivia",
    iso_alpha2_code: "BO",
    tel_country_code: "591",
    show_on_register: "1",
  },
  {
    country_id: "250",
    name: "Bonaire",
    iso_alpha2_code: "AF",
    tel_country_code: "5997",
    show_on_register: "1",
  },
  {
    country_id: "268",
    name: "Bosnia and Herzegovina",
    iso_alpha2_code: "BIH",
    tel_country_code: "387",
    show_on_register: "1",
  },
  {
    country_id: "29",
    name: "Botswana",
    iso_alpha2_code: "BW",
    tel_country_code: "267",
    show_on_register: "1",
  },
  {
    country_id: "32",
    name: "Brazil",
    iso_alpha2_code: "BR",
    tel_country_code: "55",
    show_on_register: "1",
  },
  {
    country_id: "269",
    name: "Brunei Darussalam",
    iso_alpha2_code: "AF",
    tel_country_code: "673",
    show_on_register: "1",
  },
  {
    country_id: "35",
    name: "Bulgaria",
    iso_alpha2_code: "BG",
    tel_country_code: "359",
    show_on_register: "1",
  },
  {
    country_id: "36",
    name: "Burkina Faso",
    iso_alpha2_code: "BF",
    tel_country_code: "226",
    show_on_register: "1",
  },
  {
    country_id: "37",
    name: "Burundi",
    iso_alpha2_code: "BI",
    tel_country_code: "257",
    show_on_register: "1",
  },
  {
    country_id: "38",
    name: "Cambodia",
    iso_alpha2_code: "KH",
    tel_country_code: "855",
    show_on_register: "1",
  },
  {
    country_id: "39",
    name: "Cameroon",
    iso_alpha2_code: "CM",
    tel_country_code: "237",
    show_on_register: "1",
  },
  {
    country_id: "40",
    name: "Canada",
    iso_alpha2_code: "CA",
    tel_country_code: "1",
    show_on_register: "1",
  },
  {
    country_id: "41",
    name: "Cape Verde",
    iso_alpha2_code: "CV",
    tel_country_code: "238",
    show_on_register: "1",
  },
  {
    country_id: "42",
    name: "Cayman Islands",
    iso_alpha2_code: "KY",
    tel_country_code: "345",
    show_on_register: "1",
  },
  {
    country_id: "43",
    name: "Central African Republic",
    iso_alpha2_code: "CF",
    tel_country_code: "236",
    show_on_register: "1",
  },
  {
    country_id: "44",
    name: "Chad",
    iso_alpha2_code: "TD",
    tel_country_code: "235",
    show_on_register: "1",
  },
  {
    country_id: "45",
    name: "Chile",
    iso_alpha2_code: "CL",
    tel_country_code: "56",
    show_on_register: "1",
  },
  {
    country_id: "46",
    name: "China",
    iso_alpha2_code: "CN",
    tel_country_code: "86",
    show_on_register: "1",
  },
  {
    country_id: "47",
    name: "Christmas Island",
    iso_alpha2_code: "CX",
    tel_country_code: "672",
    show_on_register: "1",
  },
  {
    country_id: "48",
    name: "Cocos (Keeling) Islands",
    iso_alpha2_code: "AF",
    tel_country_code: "672",
    show_on_register: "1",
  },
  {
    country_id: "49",
    name: "Colombia",
    iso_alpha2_code: "CO",
    tel_country_code: "57",
    show_on_register: "1",
  },
  {
    country_id: "50",
    name: "Comoros",
    iso_alpha2_code: "KM",
    tel_country_code: "269",
    show_on_register: "1",
  },
  {
    country_id: "51",
    name: "Congo",
    iso_alpha2_code: "CG",
    tel_country_code: "242",
    show_on_register: "1",
  },
  {
    country_id: "52",
    name: "Cook Islands",
    iso_alpha2_code: "CK",
    tel_country_code: "682",
    show_on_register: "1",
  },
  {
    country_id: "53",
    name: "Costa Rica",
    iso_alpha2_code: "CR",
    tel_country_code: "506",
    show_on_register: "1",
  },
  {
    country_id: "55",
    name: "Croatia",
    iso_alpha2_code: "HR",
    tel_country_code: "385",
    show_on_register: "1",
  },
  {
    country_id: "56",
    name: "Cuba",
    iso_alpha2_code: "CU",
    tel_country_code: "53",
    show_on_register: "0",
  },
  {
    country_id: "290",
    name: "Curacao",
    iso_alpha2_code: "CW",
    tel_country_code: "599",
    show_on_register: "1",
  },
  {
    country_id: "57",
    name: "Cyprus",
    iso_alpha2_code: "CY",
    tel_country_code: "357",
    show_on_register: "1",
  },
  {
    country_id: "58",
    name: "Czech Republic",
    iso_alpha2_code: "CZ",
    tel_country_code: "420",
    show_on_register: "1",
  },
  {
    country_id: "59",
    name: "Denmark",
    iso_alpha2_code: "DK",
    tel_country_code: "45",
    show_on_register: "1",
  },
  {
    country_id: "60",
    name: "Djibouti",
    iso_alpha2_code: "DJ",
    tel_country_code: "253",
    show_on_register: "1",
  },
  {
    country_id: "61",
    name: "Dominica",
    iso_alpha2_code: "DM",
    tel_country_code: "767",
    show_on_register: "1",
  },
  {
    country_id: "62",
    name: "Dominican Republic",
    iso_alpha2_code: "DO",
    tel_country_code: "809",
    show_on_register: "1",
  },
  {
    country_id: "63",
    name: "East Timor (Timor-Leste)",
    iso_alpha2_code: "TP",
    tel_country_code: "670",
    show_on_register: "1",
  },
  {
    country_id: "64",
    name: "Ecuador",
    iso_alpha2_code: "EC",
    tel_country_code: "593",
    show_on_register: "1",
  },
  {
    country_id: "65",
    name: "Egypt",
    iso_alpha2_code: "EG",
    tel_country_code: "20",
    show_on_register: "1",
  },
  {
    country_id: "66",
    name: "El Salvador",
    iso_alpha2_code: "SV",
    tel_country_code: "503",
    show_on_register: "1",
  },
  {
    country_id: "67",
    name: "Equatorial Guinea",
    iso_alpha2_code: "GQ",
    tel_country_code: "240",
    show_on_register: "1",
  },
  {
    country_id: "69",
    name: "Eritrea",
    iso_alpha2_code: "ER",
    tel_country_code: "291",
    show_on_register: "1",
  },
  {
    country_id: "70",
    name: "Estonia",
    iso_alpha2_code: "EE",
    tel_country_code: "372",
    show_on_register: "1",
  },
  {
    country_id: "71",
    name: "Ethiopia",
    iso_alpha2_code: "ET",
    tel_country_code: "251",
    show_on_register: "1",
  },
  {
    country_id: "72",
    name: "Falkland Islands",
    iso_alpha2_code: "FK",
    tel_country_code: "500",
    show_on_register: "1",
  },
  {
    country_id: "73",
    name: "Faroe Islands",
    iso_alpha2_code: "FO",
    tel_country_code: "298",
    show_on_register: "1",
  },
  {
    country_id: "144",
    name: "Federal States of Micronesia",
    iso_alpha2_code: "FM",
    tel_country_code: "691",
    show_on_register: "1",
  },
  {
    country_id: "75",
    name: "Fiji",
    iso_alpha2_code: "FJ",
    tel_country_code: "679",
    show_on_register: "1",
  },
  {
    country_id: "76",
    name: "Finland",
    iso_alpha2_code: "FI",
    tel_country_code: "358",
    show_on_register: "1",
  },
  {
    country_id: "77",
    name: "France",
    iso_alpha2_code: "FR",
    tel_country_code: "33",
    show_on_register: "1",
  },
  {
    country_id: "79",
    name: "French Guiana",
    iso_alpha2_code: "AF",
    tel_country_code: "594",
    show_on_register: "0",
  },
  {
    country_id: "81",
    name: "French Polynesia",
    iso_alpha2_code: "AF",
    tel_country_code: "689",
    show_on_register: "0",
  },
  {
    country_id: "270",
    name: "French Southern Territories",
    iso_alpha2_code: "TF",
    tel_country_code: "262",
    show_on_register: "0",
  },
  {
    country_id: "84",
    name: "Gabon",
    iso_alpha2_code: "GA",
    tel_country_code: "241",
    show_on_register: "1",
  },
  {
    country_id: "85",
    name: "Gambia",
    iso_alpha2_code: "GM",
    tel_country_code: "220",
    show_on_register: "1",
  },
  {
    country_id: "86",
    name: "Georgia",
    iso_alpha2_code: "GE",
    tel_country_code: "995",
    show_on_register: "1",
  },
  {
    country_id: "87",
    name: "Germany",
    iso_alpha2_code: "DE",
    tel_country_code: "49",
    show_on_register: "1",
  },
  {
    country_id: "88",
    name: "Ghana",
    iso_alpha2_code: "GH",
    tel_country_code: "233",
    show_on_register: "1",
  },
  {
    country_id: "89",
    name: "Gibraltar",
    iso_alpha2_code: "GI",
    tel_country_code: "350",
    show_on_register: "1",
  },
  {
    country_id: "90",
    name: "Greece",
    iso_alpha2_code: "GR",
    tel_country_code: "30",
    show_on_register: "1",
  },
  {
    country_id: "91",
    name: "Greenland",
    iso_alpha2_code: "GL",
    tel_country_code: "299",
    show_on_register: "1",
  },
  {
    country_id: "92",
    name: "Grenada",
    iso_alpha2_code: "GD",
    tel_country_code: "473",
    show_on_register: "1",
  },
  {
    country_id: "93",
    name: "Guadeloupe",
    iso_alpha2_code: "AF",
    tel_country_code: "590",
    show_on_register: "0",
  },
  {
    country_id: "94",
    name: "Guam",
    iso_alpha2_code: "AF",
    tel_country_code: "671",
    show_on_register: "0",
  },
  {
    country_id: "95",
    name: "Guatemala",
    iso_alpha2_code: "GT",
    tel_country_code: "502",
    show_on_register: "1",
  },
  {
    country_id: "96",
    name: "Guinea",
    iso_alpha2_code: "GN",
    tel_country_code: "224",
    show_on_register: "1",
  },
  {
    country_id: "97",
    name: "Guinea-Bissau",
    iso_alpha2_code: "AF",
    tel_country_code: "245",
    show_on_register: "1",
  },
  {
    country_id: "98",
    name: "Guyana",
    iso_alpha2_code: "GY",
    tel_country_code: "592",
    show_on_register: "1",
  },
  {
    country_id: "99",
    name: "Haiti",
    iso_alpha2_code: "HT",
    tel_country_code: "509",
    show_on_register: "1",
  },
  {
    country_id: "101",
    name: "Honduras",
    iso_alpha2_code: "HN",
    tel_country_code: "504",
    show_on_register: "1",
  },
  {
    country_id: "271",
    name: "Hong Kong",
    iso_alpha2_code: "HK",
    tel_country_code: "852",
    show_on_register: "1",
  },
  {
    country_id: "103",
    name: "Hungary",
    iso_alpha2_code: "HU",
    tel_country_code: "36",
    show_on_register: "1",
  },
  {
    country_id: "31",
    name: "Iceland",
    iso_alpha2_code: "IS",
    tel_country_code: "354",
    show_on_register: "1",
  },
  {
    country_id: "105",
    name: "India",
    iso_alpha2_code: "IN",
    tel_country_code: "91",
    show_on_register: "1",
  },
  // {
  //   country_id: "106",
  //   name: "Indonesia",
  //   iso_alpha2_code: "ID",
  //   tel_country_code: "62",
  //   show_on_register: "1"
  // },
  // {
  //   country_id: "107",
  //   name: "Iran",
  //   iso_alpha2_code: "IR",
  //   tel_country_code: "98",
  //   show_on_register: "0"
  // },
  {
    country_id: "108",
    name: "Iraq",
    iso_alpha2_code: "IQ",
    tel_country_code: "964",
    show_on_register: "1",
  },
  {
    country_id: "109",
    name: "Ireland",
    iso_alpha2_code: "IE",
    tel_country_code: "353",
    show_on_register: "1",
  },
  {
    country_id: "282",
    name: "Isle of Man",
    iso_alpha2_code: "IM",
    tel_country_code: "44",
    show_on_register: "1",
  },
  {
    country_id: "110",
    name: "Israel",
    iso_alpha2_code: "IL",
    tel_country_code: "972",
    show_on_register: "1",
  },
  {
    country_id: "111",
    name: "Italy",
    iso_alpha2_code: "IT",
    tel_country_code: "39",
    show_on_register: "1",
  },
  {
    country_id: "54",
    name: "Ivory Coast",
    iso_alpha2_code: "AF",
    tel_country_code: "225",
    show_on_register: "1",
  },
  {
    country_id: "112",
    name: "Jamaica",
    iso_alpha2_code: "JM",
    tel_country_code: "876",
    show_on_register: "1",
  },
  {
    country_id: "113",
    name: "Japan",
    iso_alpha2_code: "JP",
    tel_country_code: "81",
    show_on_register: "0",
  },
  {
    country_id: "114",
    name: "Jordan",
    iso_alpha2_code: "JO",
    tel_country_code: "962",
    show_on_register: "1",
  },
  {
    country_id: "272",
    name: "Kazakhstan",
    iso_alpha2_code: "KZ",
    tel_country_code: "7",
    show_on_register: "1",
  },
  {
    country_id: "116",
    name: "Kenya",
    iso_alpha2_code: "KE",
    tel_country_code: "254",
    show_on_register: "1",
  },
  {
    country_id: "117",
    name: "Kiribati",
    iso_alpha2_code: "KI",
    tel_country_code: "686",
    show_on_register: "1",
  },
  // {
  //   country_id: "118",
  //   name: "Korea, Democratic Peoples Republic (North Korea)",
  //   iso_alpha2_code: "AF",
  //   tel_country_code: "850",
  //   show_on_register: "0"
  // },
  {
    country_id: "273",
    name: "Korea, South",
    iso_alpha2_code: "AF",
    tel_country_code: "850",
    show_on_register: "0",
  },
  {
    country_id: "120",
    name: "Kuwait",
    iso_alpha2_code: "KW",
    tel_country_code: "965",
    show_on_register: "1",
  },
  {
    country_id: "121",
    name: "Kyrgyzstan",
    iso_alpha2_code: "KG",
    tel_country_code: "996",
    show_on_register: "1",
  },
  {
    country_id: "122",
    name: "Laos Peoples Democratic Republic",
    iso_alpha2_code: "AF",
    tel_country_code: "856",
    show_on_register: "1",
  },
  {
    country_id: "123",
    name: "Latvia",
    iso_alpha2_code: "LV",
    tel_country_code: "371",
    show_on_register: "0",
  },
  {
    country_id: "124",
    name: "Lebanon",
    iso_alpha2_code: "LB",
    tel_country_code: "961",
    show_on_register: "1",
  },
  {
    country_id: "125",
    name: "Lesotho",
    iso_alpha2_code: "LS",
    tel_country_code: "266",
    show_on_register: "1",
  },
  {
    country_id: "126",
    name: "Liberia",
    iso_alpha2_code: "LR",
    tel_country_code: "231",
    show_on_register: "1",
  },
  {
    country_id: "127",
    name: "Libya",
    iso_alpha2_code: "LY",
    tel_country_code: "218",
    show_on_register: "1",
  },
  {
    country_id: "128",
    name: "Liechtenstein",
    iso_alpha2_code: "LI",
    tel_country_code: "423",
    show_on_register: "1",
  },
  {
    country_id: "129",
    name: "Lithuania",
    iso_alpha2_code: "LT",
    tel_country_code: "370",
    show_on_register: "1",
  },
  {
    country_id: "130",
    name: "Luxembourg",
    iso_alpha2_code: "LU",
    tel_country_code: "352",
    show_on_register: "1",
  },
  {
    country_id: "131",
    name: "Macao",
    iso_alpha2_code: "MO",
    tel_country_code: "853",
    show_on_register: "1",
  },
  {
    country_id: "83",
    name: "Macedonia",
    iso_alpha2_code: "MK",
    tel_country_code: "389",
    show_on_register: "1",
  },
  {
    country_id: "132",
    name: "Madagascar",
    iso_alpha2_code: "MG",
    tel_country_code: "261",
    show_on_register: "1",
  },
  {
    country_id: "133",
    name: "Malawi",
    iso_alpha2_code: "MW",
    tel_country_code: "265",
    show_on_register: "1",
  },
  {
    country_id: "134",
    name: "Malaysia",
    iso_alpha2_code: "MY",
    tel_country_code: "60",
    show_on_register: "1",
  },
  {
    country_id: "135",
    name: "Maldives",
    iso_alpha2_code: "MV",
    tel_country_code: "960",
    show_on_register: "1",
  },
  {
    country_id: "136",
    name: "Mali",
    iso_alpha2_code: "ML",
    tel_country_code: "223",
    show_on_register: "1",
  },
  {
    country_id: "137",
    name: "Malta",
    iso_alpha2_code: "MT",
    tel_country_code: "356",
    show_on_register: "1",
  },
  {
    country_id: "138",
    name: "Marshall Islands",
    iso_alpha2_code: "MH",
    tel_country_code: "692",
    show_on_register: "1",
  },
  {
    country_id: "139",
    name: "Martinique",
    iso_alpha2_code: "AF",
    tel_country_code: "596",
    show_on_register: "1",
  },
  {
    country_id: "140",
    name: "Mauritania",
    iso_alpha2_code: "MR",
    tel_country_code: "222",
    show_on_register: "0",
  },
  {
    country_id: "141",
    name: "Mauritius",
    iso_alpha2_code: "MU",
    tel_country_code: "230",
    show_on_register: "1",
  },
  {
    country_id: "142",
    name: "Mayotte",
    iso_alpha2_code: "AF",
    tel_country_code: "269",
    show_on_register: "1",
  },
  {
    country_id: "143",
    name: "Mexico",
    iso_alpha2_code: "MX",
    tel_country_code: "52",
    show_on_register: "1",
  },
  {
    country_id: "275",
    name: "Moldova",
    iso_alpha2_code: "MD",
    tel_country_code: "373",
    show_on_register: "1",
  },
  {
    country_id: "276",
    name: "Monaco",
    iso_alpha2_code: "AF",
    tel_country_code: "93",
    show_on_register: "1",
  },
  {
    country_id: "147",
    name: "Mongolia",
    iso_alpha2_code: "MN",
    tel_country_code: "976",
    show_on_register: "1",
  },
  {
    country_id: "277",
    name: "Montenegro",
    iso_alpha2_code: "ME",
    tel_country_code: "382",
    show_on_register: "1",
  },
  {
    country_id: "148",
    name: "Montserrat",
    iso_alpha2_code: "MS",
    tel_country_code: "664",
    show_on_register: "1",
  },
  {
    country_id: "149",
    name: "Morocco",
    iso_alpha2_code: "MA",
    tel_country_code: "212",
    show_on_register: "1",
  },
  {
    country_id: "150",
    name: "Mozambique",
    iso_alpha2_code: "MZ",
    tel_country_code: "258",
    show_on_register: "1",
  },
  {
    country_id: "151",
    name: "Myanmar, Burma",
    iso_alpha2_code: "MM",
    tel_country_code: "95",
    show_on_register: "1",
  },
  {
    country_id: "152",
    name: "Namibia",
    iso_alpha2_code: "NA",
    tel_country_code: "264",
    show_on_register: "1",
  },
  {
    country_id: "153",
    name: "Nauru",
    iso_alpha2_code: "NR",
    tel_country_code: "674",
    show_on_register: "1",
  },
  {
    country_id: "154",
    name: "Nepal",
    iso_alpha2_code: "NP",
    tel_country_code: "977",
    show_on_register: "1",
  },
  {
    country_id: "155",
    name: "Netherlands",
    iso_alpha2_code: "NL",
    tel_country_code: "31",
    show_on_register: "1",
  },
  {
    country_id: "156",
    name: "Netherlands Antilles",
    iso_alpha2_code: "AN",
    tel_country_code: "599",
    show_on_register: "0",
  },
  {
    country_id: "157",
    name: "New Caledonia",
    iso_alpha2_code: "AF",
    tel_country_code: "687",
    show_on_register: "1",
  },
  {
    country_id: "158",
    name: "New Zealand",
    iso_alpha2_code: "NZ",
    tel_country_code: "64",
    show_on_register: "1",
  },
  {
    country_id: "159",
    name: "Nicaragua",
    iso_alpha2_code: "NI",
    tel_country_code: "505",
    show_on_register: "1",
  },
  {
    country_id: "160",
    name: "Niger",
    iso_alpha2_code: "NE",
    tel_country_code: "227",
    show_on_register: "1",
  },
  {
    country_id: "161",
    name: "Nigeria",
    iso_alpha2_code: "NG",
    tel_country_code: "234",
    show_on_register: "1",
  },
  {
    country_id: "162",
    name: "Niue",
    iso_alpha2_code: "NU",
    tel_country_code: "683",
    show_on_register: "1",
  },
  {
    country_id: "163",
    name: "Norfolk Island",
    iso_alpha2_code: "NF",
    tel_country_code: "672",
    show_on_register: "1",
  },
  {
    country_id: "164",
    name: "Northern Mariana Islands",
    iso_alpha2_code: "AF",
    tel_country_code: "968",
    show_on_register: "0",
  },
  {
    country_id: "165",
    name: "Norway",
    iso_alpha2_code: "NO",
    tel_country_code: "47",
    show_on_register: "1",
  },
  {
    country_id: "166",
    name: "Oman",
    iso_alpha2_code: "OM",
    tel_country_code: "968",
    show_on_register: "1",
  },
  {
    country_id: "167",
    name: "Pakistan",
    iso_alpha2_code: "PK",
    tel_country_code: "92",
    show_on_register: "1",
  },
  {
    country_id: "168",
    name: "Palau",
    iso_alpha2_code: "PW",
    tel_country_code: "680",
    show_on_register: "1",
  },
  {
    country_id: "169",
    name: "Panama",
    iso_alpha2_code: "PA",
    tel_country_code: "507",
    show_on_register: "1",
  },
  {
    country_id: "170",
    name: "Papua New Guinea",
    iso_alpha2_code: "PG",
    tel_country_code: "675",
    show_on_register: "1",
  },
  {
    country_id: "171",
    name: "Paraguay",
    iso_alpha2_code: "PY",
    tel_country_code: "595",
    show_on_register: "1",
  },
  {
    country_id: "172",
    name: "Peru",
    iso_alpha2_code: "PE",
    tel_country_code: "51",
    show_on_register: "1",
  },
  {
    country_id: "173",
    name: "Philippines",
    iso_alpha2_code: "PH",
    tel_country_code: "63",
    show_on_register: "1",
  },
  {
    country_id: "174",
    name: "Pitcairn Islands",
    iso_alpha2_code: "PN",
    tel_country_code: "",
    show_on_register: "1",
  },
  {
    country_id: "175",
    name: "Poland",
    iso_alpha2_code: "PL",
    tel_country_code: "48",
    show_on_register: "1",
  },
  {
    country_id: "176",
    name: "Portugal",
    iso_alpha2_code: "PT",
    tel_country_code: "351",
    show_on_register: "1",
  },
  {
    country_id: "177",
    name: "Puerto Rico",
    iso_alpha2_code: "PR",
    tel_country_code: "1787",
    show_on_register: "1",
  },
  {
    country_id: "178",
    name: "Qatar",
    iso_alpha2_code: "QA",
    tel_country_code: "974",
    show_on_register: "1",
  },
  {
    country_id: "179",
    name: "Reunion Island",
    iso_alpha2_code: "AF",
    tel_country_code: "262",
    show_on_register: "0",
  },
  {
    country_id: "180",
    name: "Romania",
    iso_alpha2_code: "RO",
    tel_country_code: "40",
    show_on_register: "1",
  },
  {
    country_id: "181",
    name: "Russian Federation",
    iso_alpha2_code: "RU",
    tel_country_code: "7",
    show_on_register: "1",
  },
  {
    country_id: "182",
    name: "Rwanda",
    iso_alpha2_code: "RW",
    tel_country_code: "250",
    show_on_register: "1",
  },
  {
    country_id: "183",
    name: "Saint Helena",
    iso_alpha2_code: "AF",
    tel_country_code: "290",
    show_on_register: "1",
  },
  {
    country_id: "184",
    name: "Saint Kitts and Nevis",
    iso_alpha2_code: "AF",
    tel_country_code: "869",
    show_on_register: "1",
  },
  {
    country_id: "186",
    name: "Saint Lucia",
    iso_alpha2_code: "AF",
    tel_country_code: "758",
    show_on_register: "1",
  },
  {
    country_id: "189",
    name: "Saint Vincent and The Grenadines",
    iso_alpha2_code: "AF",
    tel_country_code: "784",
    show_on_register: "1",
  },
  {
    country_id: "191",
    name: "San Marino",
    iso_alpha2_code: "SM",
    tel_country_code: "378",
    show_on_register: "1",
  },
  {
    country_id: "192",
    name: "Sao Tome and Principe",
    iso_alpha2_code: "AF",
    tel_country_code: "239",
    show_on_register: "1",
  },
  {
    country_id: "194",
    name: "Saudi Arabia",
    iso_alpha2_code: "SA",
    tel_country_code: "966",
    show_on_register: "1",
  },
  {
    country_id: "195",
    name: "Senegal",
    iso_alpha2_code: "SN",
    tel_country_code: "221",
    show_on_register: "1",
  },
  {
    country_id: "265",
    name: "Serbia",
    iso_alpha2_code: "RS",
    tel_country_code: "381",
    show_on_register: "1",
  },
  {
    country_id: "196",
    name: "Seychelles",
    iso_alpha2_code: "SC",
    tel_country_code: "248",
    show_on_register: "1",
  },
  {
    country_id: "197",
    name: "Sierra Leone",
    iso_alpha2_code: "SL",
    tel_country_code: "232",
    show_on_register: "1",
  },
  {
    country_id: "198",
    name: "Singapore",
    iso_alpha2_code: "SG",
    tel_country_code: "65",
    show_on_register: "1",
  },
  {
    country_id: "199",
    name: "Slovakia",
    iso_alpha2_code: "AF",
    tel_country_code: "421",
    show_on_register: "1",
  },
  {
    country_id: "200",
    name: "Slovenia",
    iso_alpha2_code: "SI",
    tel_country_code: "386",
    show_on_register: "1",
  },
  {
    country_id: "201",
    name: "Solomon Islands",
    iso_alpha2_code: "SB",
    tel_country_code: "677",
    show_on_register: "1",
  },
  {
    country_id: "202",
    name: "Somalia",
    iso_alpha2_code: "SO",
    tel_country_code: "252",
    show_on_register: "1",
  },
  {
    country_id: "203",
    name: "South Africa",
    iso_alpha2_code: "ZA",
    tel_country_code: "27",
    show_on_register: "1",
  },
  {
    country_id: "205",
    name: "Spain",
    iso_alpha2_code: "ES",
    tel_country_code: "34",
    show_on_register: "1",
  },
  {
    country_id: "206",
    name: "Sri Lanka",
    iso_alpha2_code: "LK",
    tel_country_code: "94",
    show_on_register: "1",
  },
  {
    country_id: "278",
    name: "State of Palestine",
    iso_alpha2_code: "PS",
    tel_country_code: "970",
    show_on_register: "1",
  },
  {
    country_id: "207",
    name: "Sudan",
    iso_alpha2_code: "SD",
    tel_country_code: "249",
    show_on_register: "1",
  },
  {
    country_id: "208",
    name: "Suriname",
    iso_alpha2_code: "SR",
    tel_country_code: "597",
    show_on_register: "1",
  },
  {
    country_id: "209",
    name: "Svalbard and Jan Mayen Islands",
    iso_alpha2_code: "AF",
    tel_country_code: "41",
    show_on_register: "1",
  },
  {
    country_id: "210",
    name: "Swaziland",
    iso_alpha2_code: "SZ",
    tel_country_code: "268",
    show_on_register: "1",
  },
  {
    country_id: "211",
    name: "Sweden",
    iso_alpha2_code: "SE",
    tel_country_code: "46",
    show_on_register: "1",
  },
  {
    country_id: "212",
    name: "Switzerland",
    iso_alpha2_code: "CH",
    tel_country_code: "41",
    show_on_register: "1",
  },
  {
    country_id: "213",
    name: "Syrian Arab Republic",
    iso_alpha2_code: "SY",
    tel_country_code: "963",
    show_on_register: "1",
  },
  {
    country_id: "214",
    name: "Taiwan (Republic of China)",
    iso_alpha2_code: "TW",
    tel_country_code: "886",
    show_on_register: "1",
  },
  {
    country_id: "215",
    name: "Tajikistan",
    iso_alpha2_code: "AF",
    tel_country_code: "7",
    show_on_register: "1",
  },
  {
    country_id: "216",
    name: "Tanzania",
    iso_alpha2_code: "TZ",
    tel_country_code: "255",
    show_on_register: "1",
  },
  {
    country_id: "217",
    name: "Thailand",
    iso_alpha2_code: "TH",
    tel_country_code: "66",
    show_on_register: "1",
  },
  {
    country_id: "218",
    name: "Togo",
    iso_alpha2_code: "TG",
    tel_country_code: "228",
    show_on_register: "1",
  },
  {
    country_id: "219",
    name: "Tokelau",
    iso_alpha2_code: "TK",
    tel_country_code: "690",
    show_on_register: "1",
  },
  {
    country_id: "220",
    name: "Tonga",
    iso_alpha2_code: "AF",
    tel_country_code: "676",
    show_on_register: "1",
  },
  {
    country_id: "221",
    name: "Trinidad and Tobago",
    iso_alpha2_code: "TT",
    tel_country_code: "868",
    show_on_register: "1",
  },
  {
    country_id: "222",
    name: "Tunisia",
    iso_alpha2_code: "TN",
    tel_country_code: "216",
    show_on_register: "1",
  },
  {
    country_id: "223",
    name: "Turkey",
    iso_alpha2_code: "TR",
    tel_country_code: "90",
    show_on_register: "1",
  },
  {
    country_id: "224",
    name: "Turkmenistan",
    iso_alpha2_code: "TM",
    tel_country_code: "993",
    show_on_register: "1",
  },
  {
    country_id: "225",
    name: "Turks and Caicos Islands",
    iso_alpha2_code: "TC",
    tel_country_code: "649",
    show_on_register: "1",
  },
  {
    country_id: "226",
    name: "Tuvalu",
    iso_alpha2_code: "TV",
    tel_country_code: "688",
    show_on_register: "1",
  },
  {
    country_id: "227",
    name: "Uganda",
    iso_alpha2_code: "UG",
    tel_country_code: "256",
    show_on_register: "1",
  },
  {
    country_id: "228",
    name: "Ukraine",
    iso_alpha2_code: "UA",
    tel_country_code: "380",
    show_on_register: "1",
  },
  {
    country_id: "229",
    name: "United Arab Emirates",
    iso_alpha2_code: "AE",
    tel_country_code: "971",
    show_on_register: "1",
  },
  {
    country_id: "230",
    name: "United Kingdom",
    iso_alpha2_code: "GB",
    tel_country_code: "44",
    show_on_register: "1",
  },
  {
    country_id: "233",
    name: "Uruguay",
    iso_alpha2_code: "UY",
    tel_country_code: "598",
    show_on_register: "1",
  },
  {
    country_id: "234",
    name: "Uzbekistan",
    iso_alpha2_code: "UZ",
    tel_country_code: "7",
    show_on_register: "1",
  },
  {
    country_id: "235",
    name: "Vanuatu",
    iso_alpha2_code: "VU",
    tel_country_code: "678",
    show_on_register: "1",
  },
  {
    country_id: "236",
    name: "Vatican City State",
    iso_alpha2_code: "VA",
    tel_country_code: "39",
    show_on_register: "1",
  },
  {
    country_id: "237",
    name: "Venezuela",
    iso_alpha2_code: "VE",
    tel_country_code: "58",
    show_on_register: "1",
  },
  {
    country_id: "238",
    name: "Vietnam",
    iso_alpha2_code: "VN",
    tel_country_code: "84",
    show_on_register: "1",
  },
  {
    country_id: "239",
    name: "Virgin Islands (British)",
    iso_alpha2_code: "VG",
    tel_country_code: "1284",
    show_on_register: "1",
  },
  {
    country_id: "240",
    name: "Virgin Islands (U.S.)",
    iso_alpha2_code: "AF",
    tel_country_code: "1340",
    show_on_register: "0",
  },
  {
    country_id: "241",
    name: "Wallis And Futuna Islands",
    iso_alpha2_code: "WF",
    tel_country_code: "681",
    show_on_register: "0",
  },
  {
    country_id: "243",
    name: "Yemen",
    iso_alpha2_code: "YE",
    tel_country_code: "967",
    show_on_register: "1",
  },
  {
    country_id: "246",
    name: "Zambia",
    iso_alpha2_code: "ZM",
    tel_country_code: "260",
    show_on_register: "1",
  },
  {
    country_id: "247",
    name: "Zimbabwe",
    iso_alpha2_code: "ZW",
    tel_country_code: "263",
    show_on_register: "1",
  },
];

const euCountries = [
  "AD",
  "AL",
  "AT",
  "AX",
  "BA",
  "BE",
  "BG",
  "BY",
  "CH",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FO",
  "FR",
  "GB",
  "GG",
  "GI",
  "GR",
  "HR",
  "HU",
  "IE",
  "IM",
  "IS",
  "IT",
  "JE",
  "LI",
  "LT",
  "LU",
  "LV",
  "MC",
  "MD",
  "ME",
  "MK",
  "MT",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "RS",
  "RU",
  "SE",
  "SI",
  "SJ",
  "SK",
  "SM",
  "UA",
  "VA",
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IS",
  "IE",
  "IT",
  "LV",
  "LI",
  "LT",
  "LU",
  "MT",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
  "CH",
  "GB",
];

const frCountries = [
  "FR",
  "CG",
  "MG",
  "CM",
  "CI",
  "NE",
  "BF",
  "ML",
  "SN",
  "TD",
  "TN",
  "GQ",
  "RW",
  "BE",
  "BI",
  "BJ",
  "HT",
  "TG",
  "CF",
  "CD",
  "GA",
  "DJ",
  "GN",
  "KM",
  "LU",
  "VU",
  "SC",
  "MC",
];

const deCountries = ["DE", "AT", "LI", 'CH'];

const itCountries = ["IT", "SM", "VA"];

const vietnam = "VN";

const quebec = "Quebec";
const switzerlandFr = [
  "Lausanne",
  "Geneva",
  "Neuchatel",
  "Neuchâtel",
  "Delémont",
  "Delemont",
];

module.exports = {
  countries,
  euCountries,
  frCountries,
  deCountries,
  itCountries,
  quebec,
  switzerlandFr,
  vietnam,
};

// module.exports.countries = countries;
// module.exports.euCountries = euCountries;
// CH - switz
// CA - can
