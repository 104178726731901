<template>
  <div class="risk-warning pfb text-center">
      <p v-html="translation.risk">
          
      </p>
  </div>
</template>

<script>
import Translation from '../translations'
export default {
  name: 'Risk',
  computed: {
    translation(){
      return new Translation(this.$route.meta.lang)
    }
  }
};
</script>

<style scoped lang="scss">
.risk-warning {
    background-color: #000;
    padding: .5rem;
    color: #fff;
    width: 100%;
    font-size: 12px;
    @media screen and (max-width: 740px) {
    font-size: 8px;
  }
}
</style>
