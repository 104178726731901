export default class Translation {
    constructor(locale) {
        this.translation = {}
        if (locale == 'fr') {
            this.translation.trustedPartner = 'Partenaire de Confiance'
            this.translation.fullName = 'Nom Complet'
            this.translation.email = 'Email'
            this.translation.phone = 'Numéro de téléphone'
            this.translation.terms = `IJ'ai plus de 18 ans et j'accepte les
            <a href="https://www.triomarkets.com/legal-documents/Benor_Capital_Ltd-Client_Services_Agreement.pdf" target="_blank" rel="noopener noreferrer"
              >termes & conditions </a
            >
            ainsi que
            <a href="https://www.triomarkets.com/legal-documents/Benor_Capital_Ltd-Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer"
              >la politique de confidentialité</a
            >`
            this.translation.startTrading = 'Compte ouvert'
            this.translation.risk = 'Investir de manière responsable: Les CFD sont des instruments complexes et présentent un risque élevé de perte d’argent rapide en raison de l’effet de levier. Assurez-vous que vous comprenez comment les CFD fonctionnent et que vous pouvez vous permettre de perdre votre argent.'
            // validation
            this.translation.nameRequired = 'Le nom est requis'
            this.translation.emailRequired = 'Email est requis'
            this.translation.phoneRequired = 'Le téléphone est requis'
            // message
            this.translation.success = 'Succès!'
            this.translation.verifyEmail = 'Vous serez redirigé vers la Zone Client dans 5 secondes…'
            this.translation.oops = 'Oops!'
            this.translation.takeMeToMa = 'Accédez à la Zone Client maintenant'
        } else if (locale == 'it') {
            this.translation.trustedPartner = 'Partner fidato'
            this.translation.fullName = 'Nome Completo'
            this.translation.email = 'Email'
            this.translation.phone = 'Numero di telefono'
            this.translation.terms = `Ho più di 18 anni e accetto
            <a href="https://www.triomarkets.com/legal-documents/Benor_Capital_Ltd-Client_Services_Agreement.pdf" target="_blank" rel="noopener noreferrer"
              >Termini e condizioni legali</a
            >
            e
            <a href="https://www.triomarkets.com/legal-documents/Benor_Capital_Ltd-Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer"
              >informativa sulla privacy</a
            >`
            this.translation.startTrading = 'Inizia il Trading'
            this.translation.risk = 'Investire responsabilmente: i CFD sono strumenti complessi e comportano un alto rischio di perdere denaro rapidamente a causa della leva. Dovresti considerare se comprendi come funzionano i CFD e se puoi permetterti di correre il rischio alto di perdere i tuoi soldi.'
            // validation
            this.translation.nameRequired = 'Il nome è obbligatorio'
            this.translation.emailRequired = 'E-mail richiesta'
            this.translation.phoneRequired = 'Il telefono è richiesto'
            // message
            this.translation.success = 'Successo!'
            this.translation.verifyEmail = `Verrai reindirizzato all'Area Membri in 5 secondi …`
            this.translation.oops = 'Ops!'
            this.translation.takeMeToMa = `Vai subito all'Area Clienti`

        } else {
            this.translation.trustedPartner = 'Trusted Partner'
            this.translation.fullName = 'Full Name'
            this.translation.email = 'Email'
            this.translation.phone = 'Phone number'
            this.translation.terms = `I am over 18 years of age and I accept these <br />
            <a href="https://www.triomarkets.com/legal-documents/Benor_Capital_Ltd-Client_Services_Agreement.pdf" target="_blank" rel="noopener noreferrer"
              >Legal Terms &amp; Conditions</a
            >
            and
            <a href="https://www.triomarkets.com/legal-documents/Benor_Capital_Ltd-Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer"
              >Privacy Policy</a
            >`
            this.translation.startTrading = 'Start Trading'
            this.translation.risk = `<span class="fwb">Invest responsibly:</span> CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.`
            // validation
            this.translation.nameRequired = 'Name is required'
            this.translation.emailRequired = 'Email is required'
            this.translation.phoneRequired = 'Phone is required'
            // message
            this.translation.success = 'Success!'
            this.translation.verifyEmail = 'You will be redirected to the Members Area in 5 seconds...'
            this.translation.oops = 'Oops!'
            this.translation.takeMeToMa = `Go to Member's Area now`

        }
        return this.translation
    }
}