<template>
  <div class="footer">
    <div class="container">
      <div class="row text-center footer-menu">
        <div class="col-md-4">
          <a
            :href="`https://triomarkets.${
              $route.meta.license == 'eu' ? 'eu' : 'com'
            }/about-us/legal-documents`"
            target="_blank"
            class="gold"
            >› Terms and Conditions</a
          >
        </div>
        <div class="col-md-4">
          <a
            :href="`https://triomarkets.${
              $route.meta.license == 'eu' ? 'eu' : 'com'
            }/about-us/legal-documents`"
            target="_blank"
            class="gold"
            >› Privacy Policy</a
          >
        </div>
        <div class="col-md-4">
          <a
            :href="`https://triomarkets.${
              $route.meta.license == 'eu' ? 'eu' : 'com'
            }/about-us/legal-documents`"
            target="_blank"
            class="gold"
            >› Risk Disclosure Policy</a
          >
        </div>
      </div>
      <hr />
      <div class="row">
        <div
          class="col-md-12 footer-text"
          v-html="translation.footerTextBenor"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "../translations/footer";
export default {
  name: "Footer",
  computed: {
    translation() {
      return new Footer(this.$route.meta.lang, "global");
    },
  },
  data: () => ({
    icons: [
      {
        name: "VISA Secure",
        img: "visa",
      },
      {
        name: "Wire Transfer",
        img: "wire",
      },
      {
        name: "Mastercard",
        img: "mc",
      },
      {
        name: "Neteller",
        img: "neteller",
      },
      {
        name: "Skrill",
        img: "skrill",
      },
    ],
  }),
  methods: {
    social(network) {
      let link;
      switch (network) {
        case "f":
          link = "https://www.facebook.com/triomarketsnews/";
          break;
        case "t":
          link = "https://twitter.com/TrioMarkets";
          break;
        case "l":
          link = "https://www.linkedin.com/company/triomarkets";
          break;
        case "y":
          link = "https://www.youtube.com/channel/UC_wQCTAHWrmDJqN0V7xbuBw";
          break;
      }
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css");
.footer {
  background-color: #fff;
  .footer-icons {
    padding: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    img {
      transform: scale(0.8);
    }
  }
  .contact {
    padding: 3rem 0;
    background-color: #ffffff;
    .contact-details {
      p {
        margin: 0;
        padding: 0;
      }
      img {
        margin-right: 1rem;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        &:hover {
          filter: sepia(1);
          transform: translateY(-3px);
          &:nth-child(even) {
            transform: translateY(3px);
          }
        }
      }
      color: #00162c;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .footer-menu {
    padding: 1.5rem 0 1rem;
    a {
      font-weight: bold;
      color: #00162c;
    }
  }
  hr {
    border-top: 1px solid rgba(34, 34, 34, 0.267);
    padding: 1rem 0;
  }
  .footer-text {
    color: #00162c;
    text-align: justify;
    padding-bottom: 5rem;
  }
}
</style>
