<template>
  <div class="loader df jcc aic" v-if="loader == true">
    <div v-if="loading == true" class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div v-if="success == true" class="success text-center">
      <h2>{{ messageH2 }}</h2>
      <p>{{ message }}</p>
      <div class="buttonset">
        <a class="btn submit" @click="(loader = false), goToMA()">{{translation.takeMeToMa}}</a>
      </div>
    </div>
  </div>
  <div class="form-wrapper text-center">
    <div id="logoInvesting">
      <img src="/img/logo-investing.png" alt="Investing.com Logo" />
      <h3>{{ translation.trustedPartner }}</h3>
    </div>
    <form @submit.prevent class="form df fc jcc aic text-center">
      <input
        :class="validation.inputClass"
        v-model="name"
        type="email"
        name="name"
        :placeholder="validation.name"
      />
      <input
        :class="validation.inputClass"
        v-model="email"
        type="text"
        name="email"
        :placeholder="validation.email"
      />
      <div class="phone">
        <!-- search countries popup -->
        <div v-if="countryModal == true" class="country-selector">
          <input
            type="text"
            name="searchCountry"
            id="searchCountry"
            placeholder="Search"
            v-model="searchCountry"
          />
          <ul>
            <li
              @click="setCountry(country)"
              v-for="country in filteredCountries"
              :key="country.country_id"
            >
              {{ country.name }} +{{ country.tel_country_code }}
            </li>
          </ul>
        </div>
        <!-- end countries popup -->
        <input
          :class="validation.inputClass"
          v-model="phone"
          type="number"
          name="phone"
          :placeholder="validation.phone"
        />
        <div @click="countryModal = true" class="phone-code df jcc aic">
          <img
            :src="countryFlag != null ? countryFlag : ''"
            :alt="geo != null ? geo.country_name : ''"
          />
          <p>{{ geo != null ? countryCode : "" }}</p>
        </div>
      </div>
    </form>
    <div class="disclaimer-wrapper df jcc">
      <div class="disclaimer df">
        <div
          @click="terms = !terms"
          :class="`checkbox ${terms ? 'checked' : validation.checkboxClass}`"
        ></div>
        <p v-html="translation.terms"></p>
      </div>
    </div>
    <div class="buttonset">
      <a @click="register()" class="submit">{{ translation.startTrading }}</a>
    </div>
  </div>
</template>

<script>
import Translation from "../translations";
import axios from "axios";
import { countries } from "../countries";
export default {
  name: "Form",
  computed: {
    translation() {
      return new Translation(this.$route.meta.lang);
    },
    filteredCountries() {
      return this.countries.filter((country) => {
        return (
          country.name.match(new RegExp(this.searchCountry, "i")) ||
          country.tel_country_code.match(new RegExp(this.searchCountry, "i"))
        );
      });
    },
  },
  data: () => ({
    maLink: '',
    messageH2: "",
    message: "",
    loader: false,
    loading: false,
    success: false,
    license: "global",
    countryModal: false,
    countries,
    searchCountry: "",
    terms: false,
    country: {},
    geo: null,
    countryFlag: null,
    countryCode: null,
    name: "",
    email: "",
    phone: null,
    validation: {
      email: "",
      name: "",
      phone: null,
      countryCode: null,
      checkboxClass: "",
      inputClass: "",
    },
  }),
  beforeMount() {
    this.validation.email = this.translation.email;
    this.validation.name = this.translation.fullName;
    this.validation.phone = this.translation.phone;
    axios
      .get(
        "https://api.ipgeolocation.io/ipgeo?apiKey=493169f199144919b316c9e08666cb25"
      )
      .then((res) => {
        this.geo = res.data;
        this.countryFlag = res.data.country_flag;
        this.countryCode = res.data.calling_code;
        for (let i = 0; i < this.countries.length; i++) {
          if (this.countries[i].iso_alpha2_code == res.data.country_code2) {
            this.country = this.countries[i];
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    goToMA() {
      window.location.replace(this.maLink)
    },
    setCountry(country) {
      this.country = country;
      this.countryFlag = `https://ipgeolocation.io/static/flags/${this.country.iso_alpha2_code.toLowerCase()}_64.png`;
      this.countryCode = "+" + country.tel_country_code;
      this.countryModal = false;
    },
    async register() {
      if (this.name == null || this.name.length < 1) {
        console.log(this.translation);
        this.validation.name = this.translation.nameRequired;
        this.validation.inputClass = "validation";
      } else if (this.email == null || this.email.length < 1) {
        this.validation.email = this.translation.emailRequired;
        this.validation.inputClass = "validation";
      } else if (this.phone == null || this.phone.length < 1) {
        this.validation.phone = this.translation.phoneRequired;
        this.validation.inputClass = "validation";
      } else if (this.terms == false) {
        this.validation.checkboxClass = "validation";
      } else {
        window.scrollTo(0, 0);
        this.loader = true;
        this.loading = true;
        const splittedName = this.name.includes(" ")
          ? this.name.split(" ")
          : this.name;
        let data = {
          fname: splittedName[0] ? splittedName[0] : this.name,
          lname: splittedName[1] ? splittedName[1] : "NoLastName",
          email: this.email,
          sidc: "F38AE410-C33C-40EA-A2C0-07834FD7D045",
          suid:
            this.$route.meta.lang == "it"
              ? "5F676771-9CAC-4AC5-978D-A5BC00A02883"
              : this.$route.meta.lang == "fr"
              ? "8AD719C2-2417-470B-820D-4668A98BB966"
              : "A045136F-3FA2-42D4-A2F1-DA1EA18988D3",
          httpref: location.href,
          tel_number: this.countryCode + "" + this.phone,
          country_id: this.country.country_id,
          license: this.license,
          src: this.$route.query.src,
          language: this.$route.meta.lang,
        };
        await axios({
          method: "post",
          url: "https://new-api.triomarkets.com/auth/investing",
          // url: "http://localhost:3150/auth/investing",
          data,
        })
          .then((response) => {
            if (response.data.data.data.length > 0) {
              console.log(response.data);
              this.loading = false;
              this.success = true;
              this.messageH2 = this.translation.success;
              this.message = this.translation.verifyEmail;
              this.name = "";
              this.email = "";
              this.phone = null;
              axios({
                method: 'post',
                url: 'https://new-api.triomarkets.com/auth/login',
                data: {
                  email: response.data.email,
                  password: response.data.pass,
                  license: 'global'
                }
              }).then(res => {
                if (res.data.success == true) {
                  this.maLink = res.data.data[0].url
                  setTimeout(() => {
                    window.location.replace(res.data.data[0].url);
                  }, 5000);
                }
              }).catch(err=>{console.log(err.message)})
            } else if (
              response.data.data.info.message == "invalid fname" ||
              response.data.data.info.message == "invalid lname"
            ) {
              this.validation.name = "Name can only contain letters";
            }
          })
          .catch((error) => {
            this.loading = false;
            this.success = true;
            this.messageH2 = this.translation.oops;
            this.message = error.message;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
#logoInvesting {
  img {
    max-width: 100%;
    @media screen and (max-width: 500px) {
      transform: scale(0.8);
    }
  }
  h3 {
    font-size: 1.4rem;
    font-weight: 300;
    @media screen and (max-width: 500px) {
      font-size: 1.2rem;
    }
  }
}
.form-wrapper {
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.233);
  border-radius: 20px;
  width: 803px;
  height: 558px;
  @media screen and (max-width: 820px) {
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 4rem;
    border-radius: 0;
    padding: 0.5rem;
    padding-top: 2rem;
  }
  margin-top: 3rem;
  backdrop-filter: blur(15px);
  .form {
    width: 100%;
    padding: 1rem;
    input {
      padding: 1rem;
      border: 1px solid #fff;
      margin-bottom: 15px;
      border-radius: 50px;
      text-align: center;
      width: 50%;
      @media screen and (max-width: 820px) {
        width: 100%;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .phone {
      z-index: 1;
      position: relative;
      width: 100%;
      .phone-code {
        cursor: pointer;
        padding: 0.25rem;
        position: absolute;
        left: 26%;
        bottom: 50%;
        transform: translateY(32%);
        @media screen and (max-width: 820px) {
          left: 5%;
          bottom: 50%;
          transform: translateY(32%);
        }
        img {
          margin-right: 5px;
          width: 25px;
          height: 25px;
          border-radius: 50px;
          border: 1px solid rgb(211, 211, 211);
        }
        p {
          margin-bottom: 3px;
          color: #707070;
          font-size: 14px;
        }
      }
      .country-selector {
        &::-webkit-scrollbar {
          display: none;
        }
        #searchCountry {
          padding: 0.25rem;
          padding-bottom: 0.5rem;
          border-bottom: 1px solid #007bffb6;
          border-radius: 0;
          width: 100%;
        }
        background-color: #fff;
        padding: 1rem;
        z-index: 2;
        position: absolute;
        left: -5%;
        bottom: -50%;
        height: 500px;
        width: 300px;
        @media screen and (max-width: 500px) {
          top: 0;
          left: 0;
          position: fixed;
          height: 100vh;
          width: 100%;
        }
        border-radius: 20px;
        overflow-y: scroll;
        ul {
          li {
            cursor: pointer;
            text-align: left;
            padding: 0.5rem 0.5rem;
            padding-left: 1rem;
            transition: 0.2s ease;
            &:hover {
              background-color: #007bff8e;
              color: #fff;
              border-radius: 50px;
            }
          }
        }
      }
    }
  }
  .disclaimer-wrapper {
    width: 100%;

    .disclaimer {
      .checkbox {
        cursor: pointer;
        width: 13px;
        height: 13px;
        background-color: #fff;
        border: 2px solid #fff;
        padding: 1px;
        border-radius: 50px;
        margin-top: 3px;
        margin-right: 5px;
        &.checked {
          background-color: #007bff;
        }
      }
      // width: 40%;
      p,
      a {
        font-size: 12px;
      }
      a {
        color: #007bff;
        transition: 0.5s ease-in-out;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.buttonset {
  a {
    cursor: pointer;
  }
  padding: 2.5rem 0 1rem;
  @media screen and (max-width: 500px) {
    padding: 1rem 0rem;
  }
  width: 100%;
  .submit {
    padding: 1rem 7rem;
    @media screen and (max-width: 500px) {
      padding: 1rem 7rem;
      margin-top: -3rem;
      width: 100%;
    }
    background-color: #007bff;
    color: #fff;
    border-radius: 50px;
    transition: 0.5s ease;
    &:hover {
      border-radius: 15px;
      background-color: #fff;
      color: #007bff;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.178);
    }
  }
}
.validation {
  border-color: red !important;
  &::placeholder {
    color: red;
  }
}
.loader {
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100%;
  background-color: rgba(82, 82, 82, 0.37);
  .success {
    padding: 4rem;
    background-color: #fff;
    border-radius: 15px;
    h2 {
      padding-bottom: 2rem;
    }
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid rgb(0, 119, 255);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(0, 119, 255) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
