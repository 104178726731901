export default class Footer {
  constructor(locale, license) {
    this.risk = "";
    this.translation = {};
    if (locale == "fr") {
      this.translation.email = `info@triomarkets${
        license == "eu" ? ".eu" : "global.com"
      }`;
      this.translation.phone =
        license == "eu" ? "+357 25 030056" : "+442037699474";

      this.translation.footerTextBenor = `
      <p>
      Avertissement sur les risques: Avertissement sur les risques: l’investissement dans le Forex et les Contrats pour Différence (CFD) sont hautement spéculatifs et comportent un risque de perte significatif. Choisir un effet de levier plus important pour ces produits peut entraîner des pertes plus importantes. Le fait de suivre la gestion de portefeuilles ou de copier les activités boursières d’autres traders, même s'il s'agit de traders professionnels (personnes physiques ou morales), n’est pas obligatoire et ne peut garantir le succès. Vous ne devriez pas investir dans les CFD et dans le Forex, seul ou en passant par un expert advisor (robot), ou copier l'activité de trading d'autres traders à moins que vous ne soyez prêt à subir une perte totale de votre investissement initial (dépôt). C’est pourquoi le Forex et les CFD ne sont pas forcément adaptés à tous les investisseurs. Avant de trader, évaluez votre situation financière et votre niveau d’expérience, et n’investissez que de l’argent que vous pouvez vous permettre de perdre. Les performances passées ou l’utilisation d’indicateurs financiers ne sont pas des sources d’informations fiables et ne sont pas représentatives des résultats futurs. Tous les outils utilisés, publiés ou distribués par TrioMarkets sont uniquement utilisés à des fins commerciales ou éducatives – le cas échéant – et ne doivent en aucun cas être considérés comme des conseils, stratégies ou recommandations d’investissement, implicites ou explicites, dans le cadre du trading d'un instrument financier. Veuillez vous assurer de bien comprendre les risques encourus. Pour de plus amples renseignements, veuillez consulter notre Politique de Divulgation des Risques


TrioMarkets est une marque multi-régulée. TrioMarkets (Europe) est le nom commercial d'EDR Financial Ltd, enregistrée en tant que société d'investissement chypriote («CIF») sous le numéro d'enregistrement (HE336081), conformément aux lois de la République de Chypre et agréé et réglementé par la Cyprus Securities and Exchange Commission («CySEC») sous le numéro de licence 268/15.


TrioMarkets (Global) est le nom commercial sous licence de BENOR Capital Ltd qui est enregistré en tant que courtier avec le numéro d'enregistrement 160496. TrioMarkets (Global) opère sous licence accordée par la Financial Services Commission ("FSC Maurice") sous le numéro de C118023678. Siège social: The Catalyst, Level 2, Suite 201, Plot 40, Silicon Avenue, Ebene, Mauritius et dispose de la licence pour effectuer des opérations sous le nom commercial TrioMarkets.
      </p>
      <p class="text-center">
      Restrictions régionales: Benor Capital Ltd ne propose pas ses services aux résidents des Etats-Unis d’Amérique, de l’Etat d’Israël, de la République Islamique d’Iran, de l'Indonésie et de la République Populaire Démocratique de Corée (RPDC).
      Les services ne s'adressent pas aux résidents belges.
      </p>
      <p class="text-center">
        <span class="bold">BENOR CAPITAL </span> | The Catalyst, Level 2
        | Suite 201 Plot 40 | Silicon Avenue | Ebene, Mauritius
      </p>`;
    } else if (locale == "it") {
      this.translation.email = `info@triomarkets${
        license == "eu" ? ".eu" : "global.com"
      }`;
      this.translation.phone =
        license == "eu" ? "+357 25 030056" : "+442037699474";

      this.translation.footerTextBenor = `
      <p>
      Avviso sul rischio: il Trading sul Forex e Contratti per differenza (CFD) è altamente speculativo e comporta un sostanziale rischio di perdita. La scelta di una leva finanziaria più alta su tali prodotti può comportare perdite elevate. Gestione del portfolio o copiare l'operatività di trading di altri trader, anche se possono essere trader professionisti (persone fisiche o persone giuridiche) non sono obbligatori e non possono garantire il successo. Non dovresti fare trading su CFD e Forex da solo o con l'uso di un expert advisor (robot) o non dovresti copiare l'operatività di trading di altri trader a meno che tu non sia disposto a subire una perdita totale del tuo investimento iniziale (deposito). Pertanto, Forex e CFD potrebbero non essere adatti a tutti gli investitori. Prima di fare trading considera le tue condizioni finanziarie e il tuo livello di esperienza e investi solo il denaro che puoi permetterti di perdere. I risultati passati o l'uso di indicatori finanziari non sono fonti affidabili di informazioni e non possono essere indicativi di risultati futuri. Qualsiasi materiale utilizzato, pubblicato o distribuito da TrioMarkets viene usato esclusivamente per scopi di marketing o didattici - laddove applicabile - e in nessun caso può essere considerato come consulenza o strategia o raccomandazione, implicita o esplicita, di investimento in relazione al trading di uno strumento finanziario. Se necessario, chiedi una consulenza indipendente. Assicurati di aver compreso appieno i rischi connessi. Per ulteriori informazioni, consulta la nostra Informativa sul Rischio.

      TrioMarkets è un marchio multi-regolamentato. TrioMarkets (Europe) è il nome commerciale di EDR Financial Ltd, registrata come Cyprus Investment Firm («CIF») con numero di registrazione (HE336081), ai sensi delle leggi della Repubblica di Cipro. Autorizzato e regolamentato dalla Cyprus Securities and Exchange Commission («CySEC») con il numero di licenza 268/15.
      
      
      
      TrioMarkets (Global) è un nome commerciale concesso in licenza a BENOR Capital Ltd, registrato come Investment Dealer (Broker) con numero di registrazione 160496. Concesso in licenza dalla Financial Services Commission (Mauritius) con numero di licenza C118023678. Sede legale 5 ° piano, The Catalyst, Level 2, Suite 201, Plot 40, Silicon Avenue, Ebene, Mauritius e ha la licenza per eseguire operazioni con il nome commerciale TrioMarkets.
      
      </p>
      <p class="text-center">
      Restrizioni territoriali: Benor Capital Ltd non fornisce servizi ai residenti degli Stati Uniti d'America, dello Stato di Israele, della Repubblica islamica dell'Iran, dell'Indonesia e della Repubblica democratica popolare di Corea ( RPDC)
      La distribuzione non è indirizzata al pubblico belga
      </p>
      <p class="text-center">
        <span class="bold">BENOR CAPITAL </span> | The Catalyst, Level 2
        | Suite 201 Plot 40 | Silicon Avenue | Ebene, Mauritius
      </p>`;
    } else {
      this.translation.email = `info@triomarkets${
        license == "eu" ? ".eu" : "global.com"
      }`;
      this.translation.phone =
        license == "eu" ? "+357 25 030056" : "+442037699474";

      this.translation.footerTextBenor = `
        <p>
        Risk Warning: Trading in Forex and Contracts for Difference (CFDs) is highly speculative and involves substantial risk of loss. Choosing a higher leverage on those products can lead to high losses. Following portfolio management or copying the trading activity of other traders, even though they may be professional traders (physical or legal persons) is not obligatory and cannot guarantee success. You should not trade CFDs and Forex either by yourself or with the use of an expert advisor (robot), or copy the trading activity of other traders unless you are prepared to sustain a total loss of your initial investment (deposit). Therefore, Forex and CFDs may not be suitable for all investors. Before trading, assess your financial condition and your level of experience and only invest with money you can afford to lose. Past performance or the use of financial indicators are not reliable sources of information and cannot be indicative of future results. Any material used, published or distributed by TrioMarkets is used for marketing or educational –where applicable-purposes only and in no case can they be considered as investment advice or strategy or recommendation, implied or explicit, with respect to the trading of a financial instrument. If necessary, seek independent advice. Please ensure that you fully understand the risks involved. For more information, please refer to our Risk Disclosure Policy. <br>
        TrioMarkets is a multi-regulated brand. TrioMarkets (Europe) is the trading name of EDR Financial Ltd, registered as a Cyprus Investment Firm («CIF») with the registration number (HE336081), under the Laws of the Republic of Cyprus. Licensed and regulated by the Cyprus Securities and Exchange Commission («CySEC») under license number 268/15.
        TrioMarkets (Global) is a trading name licensed to BENOR Capital Ltd which is registered as an Investment Dealer (Broker) with registration number 160496. Licensed by the Financial Services Commission (Mauritius) under license number C118023678. Registered Office 5The Catalyst, Level 2, Suite 201
        Plot 40, Silicon Avenue, Ebene, Mauritius and has the license to perform operations under the trading name TrioMarkets.
        </p>
        <p class="text-center">
          Regional restrictions: Benor Capital does not provide services to
          residents of the United States of America, State of Israel, Islamic
          Republic of Iran, Indonesia and Democratic People's Republic of
          Korea (DPRK) The distribution is not addressed to the Belgian public
        </p>
        <p class="text-center">
          <span class="bold">BENOR CAPITAL </span> | The Catalyst, Level 2 | Suite 201
          Plot 40 | Silicon Avenue | Ebene, Mauritius
        </p>`;
    }
    return this.translation;
  }
}
