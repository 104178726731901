import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/app.scss'
const app = createApp(App)
app.config.devtools = true

app.component("Risk", require("./components/Risk.vue").default);
app.component("Form", require("./components/Form.vue").default);
app.component("Footer", require("./components/Footer.vue").default);

app.use(store)
app.use(router)

app.mount('#app')

